// Dependencies
import { useState } from 'react';

// Style Imports
import "../../styles/general/cstudy_tbl.css";

// Image Imports
import tmAuthor1 from "../../assets/img/idx/tm_author_1.webp";
import tmAuthor2 from "../../assets/img/idx/tm_author_2.webp";
import tmAuthor3 from "../../assets/img/idx/tm_author_3.webp";
import tmSection1 from "../../assets/img/idx/tm_section_1.webp";
import tmSection2 from "../../assets/img/idx/tm_section_2.webp";
import tmSection3 from "../../assets/img/idx/tm_section_3.webp";

// Constants
const TESTIMONIALS = [
	{
		quote: "These fake accounts not only diluted my brand presence but also risked misleading my vast follower base with counterfeit information.",
		author: {
			name: "Alex Hormozi",
			image: tmAuthor1,
			title: "Acquisition.com"
		},
		study: {
			title: "How Alex Hormozi Stopped Fake Accounts Scamming His Customers",
			link: "/alex-hormozi-case-study",
		},
		company: {
			name: "Alex Hormozi",
			logo: tmSection1
		},
		bgColor: "#E5D7FF",
		fgColor: "dark",
	},
	{
		quote: "Since partnering with ContentRemoval in 2017, we have achieved significant improvements around our brand and IP protection. We have seen a dramatic reduction in plagiarised content resulting in a direct ROI.",
		author: {
			name: "Sam Howarth",
			image: tmAuthor2,
			title: "Sweat.com",
		},
		study: {
			title: "How Sweat Stopped a $10 Million Dollar Fraud",
			link: "/sweat-case-study",
		},
		company: {
			name: "Sweat.com",
			logo: tmSection2
		},
		bgColor: "#FAE1DF",
		fgColor: "dark",
	},
	{
		quote: "We sell our brand worldwide so the monetary value we recieved as a result of stopping fraudulent companies profiting from selling fakes of our products was invaluable!",
		author: {
			name: "Amy Hembrow",
			image: tmAuthor3,
			title: "Saski Collection",
		},
		study: {
			title: "How Saski Protects Its Brand Online",
			link: "/saski-case-study",
		},
		company: {
			name: "Saski Collection",
			logo: tmSection3
		},
		bgColor: "#FCF0E2",
		fgColor: "dark",
	}
];

// Functional Component
export default function ContentTestimonials() {
	const [currTestimonial, setCurrTestimonial] = useState(0);

	// Return layout
    return (
		<>
			<div className="tb-wd-row">
				<div className="testimonials-switcher">
					{TESTIMONIALS.map((testimonial, idx) => (
						<button key={`lnd-tmsw-${testimonial.company.name}`} className={(currTestimonial === idx) ? "sel" : ""} onClick={()=>{setCurrTestimonial(idx)}}>
							<img src={testimonial.company.logo.src} alt={testimonial.company.name} />
							<p>{testimonial.company.name}</p>
						</button>
					))}
				</div>
			</div>
			<div className="tb-wd-row">
				<div className="testimonials-content">
					<div className={`l ${TESTIMONIALS[currTestimonial].fgColor}`} style={{ 'backgroundColor': TESTIMONIALS[currTestimonial].bgColor }}>
						<p>{TESTIMONIALS[currTestimonial].quote}</p>
						<div className="author">
							<img src={TESTIMONIALS[currTestimonial].author.image.src} alt={TESTIMONIALS[currTestimonial].author.name} />
							<div>
								<p>{TESTIMONIALS[currTestimonial].author.name}</p>
								<p>{TESTIMONIALS[currTestimonial].author.title}</p>
							</div>
						</div>
					</div>
					<div className="r">
						<a href={TESTIMONIALS[currTestimonial].study.link} target="_blank" rel="noopener noreferrer">
							Read Now
							<span>→</span>
						</a>
						<p>{TESTIMONIALS[currTestimonial].study.title}</p>
					</div>
				</div>
			</div>
		</>
	);
} 